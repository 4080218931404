import * as React from "react";
import { PlasmicButtonMegaUltraBase } from "./plasmic/placeit/PlasmicButtonMegaUltraBase";

function ButtonMegaUltraBase_(props, ref) {
  const { plasmicProps } = PlasmicButtonMegaUltraBase.useBehavior(props, ref);
  return <PlasmicButtonMegaUltraBase {...plasmicProps} />;
}

const ButtonMegaUltraBase = React.forwardRef(ButtonMegaUltraBase_);

export default Object.assign(ButtonMegaUltraBase, { __plumeType: "button" });
